@use "sass:color";@use 'default' as *;.Tile {
  .tile-header {
    border-bottom: 1px solid $custom-light-grey;

    img {
      margin-right: 1rem;
    }

    .Icon {
      margin-right: 1rem;
    }

    .tile-filter {
      font-size: 13px;
      font-weight: 300;
      width: 300px;
    }
  }

  .NoDataComponent {
    font-size: 1.75rem;
  }
}
