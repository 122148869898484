@use "sass:color";@use 'default' as *;.EdaVisualizationModal {
  max-width: calc(100vw - 65px);
  padding: 0.5rem;

  @media screen and (min-width: 2065px) {
    max-width: 2000px;
  }

  .DataAlert {
    margin-bottom: 2em;
  }

  .modal-two-button-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
