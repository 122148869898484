@use "sass:color";@use 'default' as *;.CustomFlowNode {
  display: flex;
  flex-direction: column;
  align-items: center;

  .custom-flow-node-label {
    position: absolute;
    top: 100%;
    text-wrap: nowrap;
  }
  .customNodeBody {
    background-size: contain;
    border: 0px;
    position: relative;
    overflow: visible;
    border-radius: 50px;
    .customHandle {
      visibility: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      transform: none;
      border: none;
      opacity: 0;
    }
  }
}
