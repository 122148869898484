@use "sass:color";@use 'default' as *;.IconSelectionModal {
  .mantine-ActionIcon-root {
    border: 2px solid transparent;
    padding: 2px;

    &:hover {
      border-color: $custom-light-grey;
    }

    &.icon-selected {
      border-color: $node-energy-black;
    }
  }

  .mantine-ActionIcon-icon img {
    width: 100%;
    height: 100%;
  }
}
