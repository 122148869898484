// metronic states
$brand-color: #00a79d;
$secondary-brand-color: #c27271;

// bootstrap states
$primary-color: #5867dd;
$primary-hover-color: #2739c1;
$success-color: #34bfa3;
$info-color: #36a3f7;
$warning-color: #ffb822;
$danger-color: #f4516c;

// bootstrap colors
$node-energy-black: #45474d;

$input-border-color: #ebedf2;

$custom-grey: #666771;
$custom-light-grey: #d3d3d3;
$custom-very-light-grey: #f0f0f0;

// special button colours
$status-confirmed-color: #fff;
$status-confirmed-background: #34bfa3;
$status-confirmed-border: #34bfa3;
$status-confirmed-finished-color: #fff;
$status-confirmed-finished-background: #1f8d77;
$status-confirmed-finished-border: #1f8d77;
$status-unclear-color: #fff;
$status-unclear-background: #36a3f7;
$status-unclear-border: #36a3f7;
$status-rejected-color: #212529;
$status-rejected-background: #fff;
$status-rejected-border: #ebedf2;
$brand-color-with-opacity: rgba(0, 167, 157, 0.3);
