@use "../themes/default.scss" as default;

body {
  // set base fontsize back to our legacy 13px (from Metronic/Bootstrap styles)
  // needs !important to ensure priority in build version which currently has reversed order of index.css & vendor.css (possibly Webpack bug)
  font-size: var(--mantine-font-size-sm) !important;
}

.mantine-SegmentedControl-label {
  /* This overrides a default Metronic style that sets the label to have a margin for some reason. */
  margin: 0px;
}

.mantine-Tooltip-tooltip {
  border: 1px solid default.$custom-light-grey;
  color: default.$status-rejected-color !important; /* Workaround for Mantine overriding own class */
  font-size: 0.875rem;
  font-weight: 400;
  padding: 7px 20px 7px 20px;
}

.mantine-Tooltip-arrow {
  border: 1px solid default.$custom-light-grey;
}

.mantine-NavLink-root:hover {
  text-decoration: none;
}

.mantine-Accordion-chevron {
  margin-inline-start: var(--mantine-spacing-md);
  margin-inline-end: var(--mantine-spacing-xs);
  color: darkgray;

  &[data-rotate] {
    transform: rotate(90deg);
  }
}

// added `div` & `button` to increase specificity, due to same issue as with font-size on top of this file
div.mantine-Accordion-item {
  background: white;
  border-width: 0;
  border-radius: 0;
  box-shadow: var(--mantine-shadow-md);
}

button.mantine-Accordion-control {
  height: 5rem;

  &:hover {
    background: initial;
  }
}

.mantine-Accordion-content {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-lg);
}

.mantine-Group-root,
.mantine-Title-root {
  &[data-variant="paper-header"] {
    margin-inline: calc(-1 * var(--mantine-spacing-lg));
    margin-bottom: var(--mantine-spacing-xl);
    border-bottom: 1px solid default.$input-border-color;
    padding: var(--mantine-spacing-lg);
    padding-top: 0;
    font-size: 1.3rem;
    font-weight: normal;
  }
}

.mantine-Title-root {
  &[data-variant="paper-header-font"] {
    font-size: 1.3rem;
    font-weight: normal;
  }
}

.mantine-Group-root {
  &[data-variant="paper-footer"] {
    margin-inline: calc(-1 * var(--mantine-spacing-lg));
    margin-top: var(--mantine-spacing-xl);
    border-top: 1px solid default.$input-border-color;
    padding: var(--mantine-spacing-lg);
    padding-bottom: 0;
  }
}

/** MANTINE-REACT-TABLE */
.mrt-table {
  button[disabled] {
    .edit-icon,
    .delete-icon {
      opacity: 0.5;
      color: default.$custom-grey;
      cursor: inherit;
    }
  }
}

.mrt-no-data-component {
  text-align: center;
  margin: 10px;

  p {
    margin: 0;
  }
}

.mrt-table-paper .mantine-Alert-root {
  margin-top: -10px;
  margin-bottom: 10px;
}

.mrt-global-filter-text-input {
  // fixing wrong margin when "positionGlobalFilter" is set to "left" – will be obsolete sometime in the future
  // no dedicated MRT issue exists, but the author already committed something to fix:
  // https://github.com/KevinVandy/mantine-react-table/commit/881a8ee3e4a5ae354c1504a05a54626c9330b79a
  margin-inline: 0 !important;
}

.mrt-create-button {
  order: 2;
  margin-left: auto;
}

.mrt-delete-button {
  order: 3;
  margin-left: 3px;

  &:not([disabled]) {
    border: none !important;
  }

  &:enabled {
    background-color: white !important;
    color: default.$danger-color !important;

    i {
      color: default.$danger-color !important;
    }
  }
}

.mantine-Table-thead {
  .mantine-Table-th {
    background-color: default.$brand-color;
    color: #fff;

    .mantine-ActionIcon-root {
      color: #fff;
    }

    .mantine-ActionIcon-root[data-active] svg {
      fill: #fff;
    }
  }

  /* Fix for aligning the sort icon with the header text */
  .mrt-table-head-cell-labels {
    align-items: flex-start;

    .mrt-table-head-sort-button {
      --ai-size: var(--ai-size-xs);
    }
  }

  /* Fix for aligning the ... menu icon with the header text */
  .mrt-table-head-cell-content-actions {
    align-self: flex-start;
  }

  .header-with-help-icon {
    display: flex;
  }

  .help-icon {
    color: #fff;
    font-size: 1.3rem;
    margin-left: 5px;
  }

  .mantine-ActionIcon-icon {
    .icon-dots-vertical {
      font-size: 1rem;

      &:hover {
        color: default.$custom-very-light-grey;
      }
    }
  }
}

.mrt-edit-action-buttons {
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
}

.mrt-table-pagination {
  padding: 0.625rem 0.75rem;
  gap: var(--mantine-spacing-md);

  .mantine-Select-input {
    height: var(--input-height-xs);
    min-height: var(--input-height-xs);
    padding-inline-start: 10px;
    padding-inline-end: 27px;
    font-size: var(--mantine-font-size-xs);
  }
}
