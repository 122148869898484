@use "sass:color";@use 'default' as *;.opti-node-info-text {
  display: flex;
  flex-direction: column;
  font-size: 1.3em;
  color: $custom-grey;
}

.onboarding-wizard-container {
  .onboarding-wizard-document-row {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .onboarding-wizard-document-container {
      cursor: pointer;
      text-decoration: none;
      color: $node-energy-black;
      font-weight: 500;
      padding: 5px;
      font-size: 1.1rem;
      &:hover {
        border-radius: 4px;
        background-color: $custom-very-light-grey;
      }
      .onboarding-wizard-document-icon {
        color: $brand-color;
        margin-right: 5px;
      }
    }
  }
}
