@use "sass:color";@use 'default' as *;.EnergyDataUploadFlow {
  display: flex;
  flex-flow: column;
  align-items: center;

  .ExplanationText {
    margin: 2em auto;
    margin-top: 0;
    width: 90%;

    section {
      margin-bottom: 1rem;
    }

    .EnergyDataTemplateDownload {
      margin-bottom: 2rem;
    }
  }
}
