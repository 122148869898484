@use "sass:color";@use 'default' as *;.EnergyDataQuality {
  .data-quality-percent {
    font-size: var(--mantine-font-size-lg);
    line-height: 1;
  }

  .data-quality-details {
    width: 50%;

    a {
      color: $primary-color;
    }

    button:focus {
      outline: 0;
    }
  }

  .data-quality-ok {
    margin-right: 0.5rem;
  }

  .extendable-toggle {
    margin-left: var(--mantine-spacing-xs);
  }
}
