@use "sass:color";@use 'default' as *;.EnergyDataTemplateDownload {
  .explanation {
    margin-bottom: 2em;
  }

  .template-download {
    > :first-child {
      margin-bottom: 10px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .SpinButton {
      margin-top: 8px;
      width: 16rem;
    }
  }
}

.energy-data-template-download-confirmation-body p:last-child {
  margin: 0;
}
