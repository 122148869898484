@use "sass:color";@use 'default' as *;.MantineBarChart {
  user-select: none;
  overflow-y: hidden;

  .zoom-out-btn {
    font-size: 10px;
    padding: 0.4rem 0.5rem;
  }

  &.ChartTooltip {
    .dot {
      border-radius: 100px;
      height: 10px;
      width: 10px;
    }
  }
}
