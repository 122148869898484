@use "sass:color";@use 'default' as *;.PvPlantsFields {
  .single-plant {
    .delete-icon {
      color: $danger-color;
    }
  }

  .form-item-info-text-container {
    margin-top: 0px;
  }
}
