@use "sass:color";@use 'default' as *;.m-body > div {
  width: 100%;
}

#content-and-subheader {
  display: flex;
  flex-flow: column;

  .m-content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  &:has(.scrollable-main-content) {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    height: calc(100vh - 140px) !important;
  }
}
