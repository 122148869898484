@use "sass:color";@use 'default' as *;.NodePopup {
  min-width: 170px;

  .custom-icon {
    width: 32px;
    height: 32px;
    padding: 2px;
    border: 1px solid black;
    margin: 3px;
  }

  .custom-icon:hover {
    cursor: pointer;
    border-color: $brand-color;
  }

  .selected-icon {
    border-color: $brand-color;
  }
}
