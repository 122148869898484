@use "sass:color";@use 'default' as *;.CustomMeteringFlowNode {
  display: flex;
  flex-direction: column;
  align-items: center;

  .custom-flow-node-label {
    position: absolute;
    top: 100%;
    text-wrap: nowrap;
  }
  .customHandle {
    width: 10px;
    height: 10px;
    &.hidden {
      visibility: hidden;
    }
  }
  .customNodeBody {
    background-size: contain;
    border: 0px;
    position: relative;
    overflow: visible;
    border-radius: 50px;
    &.mcMeterBillingRelevant {
      border: 3px solid $danger-color;
    }
  }
}
