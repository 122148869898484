@use "sass:color";@use 'default' as *;.TitleWithStatusSymbol {
  display: flex;
  align-items: center;

  .StatusSymbol {
    margin-right: 10px;
  }

  .status-symbol-loader {
    margin: 0 5px;
    font-size: 2rem;
  }
}
