@use "sass:color";@use 'default' as *;.DataGaps {
  .data-gaps-origin-info {
    width: 100%;
    .data-gaps-origin-info-meter-name {
      font-weight: 700;
    }
  }

  .accordion-control-inner-icon {
    margin-right: 0.75rem;
  }
}
